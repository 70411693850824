import { createAsyncThunk } from '@reduxjs/toolkit';
import { EAuth } from './constants';
import apiClient, { removeAuthTokens, saveTokens } from '../../middleware/apiClient';

interface ISignUp {
  hashCode?: string;
  password: string;
}

const signUp = createAsyncThunk(EAuth.signUp, async (payload: ISignUp) => {
  const response = await apiClient.post('auth/signUp', payload);
  console.log('RESPONSE FROM SIGN UP: ', response);
  const {
    data: { accessToken, refreshToken, ...data },
  } = response;
  removeAuthTokens();
  saveTokens(accessToken, refreshToken);

  return data;
});

export default signUp;
