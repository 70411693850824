import { IInviteAdmin } from 'features/users/inviteAdminSlice';
import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { HTMLElementType } from 'types';

interface INewAdminForm extends HTMLElementType {
  formData: IInviteAdmin;
  handleFieldChange: (e: any) => void;
}

const NewAdminForm: FC<INewAdminForm> = function LoginForm({ formData, handleFieldChange }) {
  return (
    <Form>
      <Form.Group className='mb-3'>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          placeholder={'Email address'}
          value={formData.emailAddress}
          name='emailAddress'
          onChange={handleFieldChange}
          type='email'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>First Name</Form.Label>
        <Form.Control
          placeholder={'First Name'}
          value={formData.firstName}
          name='firstName'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          placeholder={'Last Name'}
          value={formData.lastName}
          name='lastName'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>
    </Form>
  );
};

export default NewAdminForm;
