import { createSlice } from '@reduxjs/toolkit';
import { IBasicUser } from '../../types/auth/user';
import fetchUsers from './fetchUsersSlice';

export interface UsersState {
  loading: boolean;
  loaded: boolean;
  users: IBasicUser[] | any;
}

const initialState: UsersState = {
  loading: true,
  loaded: false,
  users: [],
};

const { reducer } = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchUsers.pending, state => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          users: action.payload,
        };
      }),
});

export default reducer;
