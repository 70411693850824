export enum ELocalStoreKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum ERole {
  ADMIN = 'admin',
  RETAIL = 'retail',
  PLAYER = 'player',
}

export enum EUserStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
