import { configureStore } from '@reduxjs/toolkit';
import loggerMiddleware from './middleware/logger';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware().prepend(loggerMiddleware);
    }
    return getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
