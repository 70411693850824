import React from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import ErrorLayout from './layouts/ErrorLayout';
import Error404 from './components/errors/Error404';
import Error500 from './components/errors/Error500';
import AuthLayout from './layouts/AuthLayout';
import Login from './components/auth/Login';
import ROUTES from './routes/constants';
import { ToastContainer } from 'react-toastify';
import PasswordReset from './components/auth/PasswordReset';
import ForgotPassword from './components/auth/ForgotPassword';
import ProtectedRoute from './routes/ProtectedRoute';
import Dashboard from './components/dashboard/Dashboard';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useAppDispatch } from './hooks/redux.hooks';
import fetchMe from './features/auth/fetchMeSlice';
import { ELocalStoreKeys } from './types/enums';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import Users from './components/users/Users';
import Signup from 'components/auth/Signup';
import { RootState } from 'store';
import apiClient from 'middleware/apiClient';
import Establishments from 'components/establishments/Establishments';

const checkTokenUserLoader = (dispatch: ThunkDispatch<RootState, any, Action>) => async () => {
  console.log('checkTokenUserLoader');
  const token =
    typeof localStorage !== 'undefined' && localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
  if (token) {
    const user = await dispatch(fetchMe())
      .unwrap()
      .then(data => {
        return data;
      });
    return user;
  }
  return {};
};

const checkInviteLoader = async ({ params }: any) => {
  if (params.hash) {
    const { data } = await apiClient.get(`invite-links/${params.hash}`);

    return data;
  }
  return { email: null, role: null };
};

const router = (dispatch: ThunkDispatch<RootState, any, Action>) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route id='root'>
        <Route path='/' element={<Navigate to={ROUTES.DASHBOARD} replace />} />
        <Route element={<AuthLayout />}>
          <Route path={ROUTES.AUTH} element={<Login />} />
          <Route path={ROUTES.PASSWORD_RESET} element={<PasswordReset />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route
            path={`${ROUTES.SIGN_UP}/:hash?`}
            element={<Signup />}
            loader={checkInviteLoader}
          />
        </Route>
        <Route element={<ProtectedRoute />} loader={checkTokenUserLoader(dispatch)}>
          <Route element={<AuthenticatedLayout />}>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.USERS} element={<Users />} />
            <Route path={ROUTES.ESTABLISHMENTS} element={<Establishments />} />
          </Route>
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path='errors/404' element={<Error404 />} />
          <Route path={ROUTES.ERROR_500} element={<Error500 />} />
          <Route path='*' element={<Error404 />} />
        </Route>
      </Route>
    )
  );

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <RouterProvider router={router(dispatch)} />
      <ToastContainer />
    </div>
  );
};

export default App;
