import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ROUTES from './constants';
import { ELocalStoreKeys } from '../types/enums';

interface ProtectedRouteProps {
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = ROUTES.AUTH }) => {
  const token =
    typeof localStorage !== 'undefined' && localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
  if (token === null || token === 'undefined') {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
