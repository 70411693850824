import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../middleware/apiClient';
import { EEstablishment } from './constants';

const fetchEstablishments = createAsyncThunk(EEstablishment.fetchAll, async () => {
  const { data } = await apiClient.get(`establishments`);
  return data;
});

export default fetchEstablishments;
