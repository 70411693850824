import { FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ToastService } from '../../services/ToastService';
import ROUTES from '../../routes/constants';
import { useAppDispatch } from '../../hooks/redux.hooks';
import fetchMe from '../../features/auth/fetchMeSlice';
import signUp from 'features/auth/signUpSlice';

interface ISignUpForm {
  hashCode?: string;
  email: string;
}

type Inputs = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUpForm: FC<ISignUpForm> = ({ hashCode, email }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: email || '',
      password: '',
      confirmPassword: '',
    },
  });
  const { password } = watch();
  // Handler
  const onSubmit: SubmitHandler<Inputs> = data => {
    dispatch(signUp({ hashCode, password: data.password }))
      .unwrap()
      .then((data: any) => {
        if (data) {
          ToastService.success('Sign Up successful');
          dispatch(fetchMe())
            .unwrap()
            .then(user => {
              if (user) {
                navigate(ROUTES.DASHBOARD);
              }
            });
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className='mb-3'>
        <Form.Label>Email address</Form.Label>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <Form.Control placeholder='Email address' disabled={true} type='email' {...field} />
          )}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Password</Form.Label>
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <Form.Control
              placeholder='Password'
              type='password'
              isInvalid={!!errors.password}
              {...field}
            />
          )}
          rules={{
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Your password needs to be at least 6 characters',
            },
          }}
        />
        <Form.Control.Feedback type='invalid'>{errors.password?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label>Confirm Password</Form.Label>
        <Controller
          name='confirmPassword'
          control={control}
          render={({ field, fieldState }) => (
            <Form.Control
              placeholder='Confirm Password'
              type='password'
              isInvalid={!!errors.confirmPassword}
              {...field}
            />
          )}
          rules={{
            validate: (value: string) =>
              (password !== value && 'Your passwords do not match') || true,
          }}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.confirmPassword?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Button type='submit' color='primary' className='mt-3 w-100'>
          Sign Up
        </Button>
      </Form.Group>
    </Form>
  );
};

export default SignUpForm;
