import { createAsyncThunk } from "@reduxjs/toolkit";
import { EAuth } from "./constants";
import apiClient, { removeAuthTokens } from "middleware/apiClient";

const logout = createAsyncThunk(
    EAuth.logout,
    async () => {
      try {
        const { data } = await apiClient.get('auth/logout');
        return data;
      } finally {
        removeAuthTokens();
      }
    }
  )
  
  export default logout;