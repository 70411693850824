import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';

import Background from './Background';
import { ISection } from '../../types/common/section';


const Section: FC<ISection> = function Section({
	fluid = false,
	bg,
	image,
	overlay,
	position,
	video,
	bgClassName,
	className,
	children,
	...rest
}) {
	const bgProps = { image, overlay, position, video, className }
	bgClassName && (bgProps.className = bgClassName)

	return (
		<section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
			{image && <Background {...bgProps} />}
			<Container fluid={fluid}>{children}</Container>
		</section>
	)
}

export default Section