import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux.hooks';
import { getMeSelector } from '../features/auth/authSelector';
import { IBasicUser } from '../types/auth/user';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROUTES from '../routes/constants';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logout from 'features/auth/logoutSlice';

const AuthenticatedLayout: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getMeSelector) as IBasicUser;

  useEffect(() => {
    if (!user) {
      navigate(ROUTES.AUTH);
    }
  }, [navigate, user]);

  const onLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate(ROUTES.AUTH));
  };

  return (
    <>
      {user && (
        <>
          <Navbar expand='lg' className='bg-body-tertiary'>
            <Container>
              <Navbar.Brand as={Link} to={ROUTES.DASHBOARD}>
                Phrenzi
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='navbarScroll' />
              <Navbar.Collapse id='navbarScroll'>
                <Nav className='me-auto my-2 my-lg-0'>
                  <Nav.Link as={Link} to={ROUTES.USERS} className='ml-auto'>
                    Users
                  </Nav.Link>
                  <Nav.Link as={Link} to={ROUTES.ESTABLISHMENTS} className='ml-auto'>
                    Establishments
                  </Nav.Link>
                </Nav>
                <Nav className='d-flex'>
                  <Nav.Link className='pl-2' onClick={onLogout}>
                    Logout
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Outlet />
        </>
      )}
    </>
  );
};

export default AuthenticatedLayout;
