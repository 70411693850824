import { useState, FC } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ILoginForm } from '../../types/auth/loginForm';
import { ToastService } from '../../services/ToastService';
import ROUTES from '../../routes/constants';
import { useAppDispatch } from '../../hooks/redux.hooks';
import login from '../../features/auth/loginSlice';
import fetchMe from '../../features/auth/fetchMeSlice';

const LoginForm: FC<ILoginForm> = function LoginForm({ hasLabel }) {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Handler
  const handleSubmit = (e: any): void => {
    e.preventDefault();
    console.log('dispatching login');
    dispatch(login(formData))
      .unwrap()
      .then((data: any) => {
        console.log('DATA: ', data);
        if (data) {
          ToastService.success('Login successful');
          dispatch(fetchMe())
            .unwrap()
            .then(user => {
              if (user) {
                navigate(ROUTES.DASHBOARD);
              }
            });
        }
      });
  };

  const handleFieldChange = (e: any): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name='email'
          onChange={handleFieldChange}
          type='email'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name='password'
          onChange={handleFieldChange}
          type='password'
        />
      </Form.Group>

      <Row className='justify-content-between align-items-center'>
        <Col xs='auto'>
          <Form.Check type='checkbox' id='rememberMe' className='mb-0'>
            <Form.Check.Input
              type='checkbox'
              name='remember'
              checked={formData.remember}
              onChange={(e: any): void =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className='mb-0 text-700'>Remember me</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs='auto'>
          <Link className='fs--1 mb-0' to={ROUTES.FORGOT_PASSWORD}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type='submit'
          color='primary'
          className='mt-3 w-100'
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
