import { FC } from 'react';
import Flex from '../common/Flex';
import LoginForm from './LoginForm';

const Login: FC = function Login() {
    return (
        <>
            <Flex justifyContent="between" alignItems="center" className="mb-2">
                <h5>Log in</h5>
            </Flex>
            <LoginForm hasLabel={false} />
        </>
    )
};
export default Login;
