import axios, { AxiosError, AxiosResponse } from 'axios';
import { ELocalStoreKeys } from '../types/enums';
import config from '../app.config';
import { Store } from 'redux';
import { ToastService } from '../services/ToastService';
import ToastMessage from '../components/Toast/ToastMessage';
import { EAuth } from 'features/auth/constants';

export const getToken = () => {
  return localStorage.getItem(ELocalStoreKeys.ACCESS_TOKEN);
};

let isRefreshing = false;
let failedQueue: Array<any> = [];

const defaultHeaders = { 'Content-Type': 'application/json' };
const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
  headers: defaultHeaders,
  timeout: config.apiTimeout,
});

const processQueue = (error: AxiosError | Error | null, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleResponse = (response: AxiosResponse) => {
  return response;
};

const handleResponseError = async (error: any, store: Store) => {
  console.log('API CLIENT: HANDLE RESPONSE ERROR ', error.response);
  const { response } = error;
  const refreshToken = localStorage.getItem(ELocalStoreKeys.REFRESH_TOKEN);

  if (response) {
    console.log('REsponse error: ', response);
    if (response.status >= 500 && response.status < 600) {
      console.error({
        title: 'Internal server error',
        text: 'Server response with error, please try again later',
      });
    }
    console.log('Current URL: ', error.config.url);
    if (response.status === 401 && error.config.url !== EAuth.login) {
      console.log('Token expired, refreshing...');
      if (refreshToken && refreshToken !== 'undefined') {
        console.log('have refresh token: ', refreshToken);
        const originalRequest = error.config;

        if (isRefreshing) {
          try {
            await new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            });
            return apiClient(originalRequest);
          } catch (err) {
            return await Promise.reject(err);
          }
        }

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          axios
            .post(
              `/auth/new-tokens`,
              {},
              {
                headers: {
                  'Refresh-Auth': refreshToken,
                },
              }
            )
            .then(({ data }) => {
              saveTokens(data.accessToken, data.refreshToken);
              processQueue(null, data.accessToken);
              resolve(apiClient(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              console.error('Error on refresh');
              removeAuthTokens();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              typeof window !== 'undefined' ? (window.location.href = '/auth') : null;
              reject(err.response.data);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else {
        removeAuthTokens();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        typeof window !== 'undefined' ? (window.location.href = '/auth') : null;
        console.error('Refresh token not found');
      }
    }
  }
  ToastService.error(ToastMessage({ title: 'Error', body: response.data.message }), {
    toastId: error.config?.url,
  });
  return Promise.reject(response.data);
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ELocalStoreKeys.ACCESS_TOKEN, accessToken);
  localStorage.setItem(ELocalStoreKeys.REFRESH_TOKEN, refreshToken);
};

export const removeAuthTokens = () => {
  localStorage.removeItem(ELocalStoreKeys.ACCESS_TOKEN);
  localStorage.removeItem(ELocalStoreKeys.REFRESH_TOKEN);
};

export const setupResponseInterceptor = (store: Store) => {
  apiClient.interceptors.request.use(config => {
    const token = getToken();
    console.log('Request with token: ', token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  apiClient.interceptors.response.use(handleResponse, error => handleResponseError(error, store));
};

export default apiClient;
