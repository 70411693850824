import { createAsyncThunk } from '@reduxjs/toolkit';
import { EAuth } from './constants';
import apiClient from '../../middleware/apiClient';

const fetchMe = createAsyncThunk(EAuth.me, async () => {
  const { data } = await apiClient.get(`auth/me`);
  return data;
});

export default fetchMe;
