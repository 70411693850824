import { FC, useEffect, useState } from 'react';
import { Button, Container, Row, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { IBasicEstablishment } from 'types/establishments/establishment';
import fetchEstablishments from 'features/establishments/fetchAllEstablishmentsSlice';
import { fetchAllEstablishmentsSelector } from 'features/establishments/fetchAllEstablishmentsReducer';
import NewEstablishmentModal from './NewEstablishmentModal';

const Establishments: FC = () => {
  const establishmentList = useAppSelector(fetchAllEstablishmentsSelector) as IBasicEstablishment[];
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  //get the list of users from the app dispatch
  useEffect(() => {
    dispatch(fetchEstablishments());
  }, [dispatch]);

  const handleAddClick = () => {
    setModalOpen(true);
  };

  return (
    <Container>
      <Row>
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Owner</th>
              <th>Address</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {establishmentList.map(establishment => (
              <tr key={establishment.id}>
                <td>{establishment.name}</td>
                <td>{establishment.ownerName}</td>
                <td>{establishment.address}</td>
                <td>{establishment.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Button variant='primary' onClick={handleAddClick}>
        Add Establishment
      </Button>
      <NewEstablishmentModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </Container>
  );
};

export default Establishments;
