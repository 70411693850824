import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/redux.hooks';
import { getMeSelector } from '../../features/auth/authSelector';
import { IBasicUser } from '../../types/auth/user';
import { Container } from 'react-bootstrap';

const Dashboard: FC = function Dashboard() {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  return (
    <Container>
      <h2>Dashboard</h2>
      <div>Welcome {me.firstName}</div>
    </Container>
  );
};

export default Dashboard;
