interface AppConfig {
  apiBaseUrl: string;
  apiTimeout: number;
}

const config: AppConfig = {
  apiBaseUrl: process.env.REACT_APP_API_URL || "http://localhost:3000",
  apiTimeout: 10000,
};

export default config;
