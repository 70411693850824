import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { HTMLElementType } from 'types';
import { ICreateEstablishment } from '../../features/establishments/createEstablishmentSlice';

interface INewEstablishmentForm extends HTMLElementType {
  formData: ICreateEstablishment;
  handleFieldChange: (e: any) => void;
}

const NewEstablishmentForm: FC<INewEstablishmentForm> = function LoginForm({
  formData,
  handleFieldChange,
}) {
  return (
    <Form>
      <Form.Group className='mb-3'>
        <Form.Label>Establishment Name</Form.Label>
        <Form.Control
          placeholder={'Establishment Name'}
          value={formData.name}
          name='name'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Owner Name</Form.Label>
        <Form.Control
          placeholder={'Owner Name'}
          value={formData.ownerName}
          name='ownerName'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Address</Form.Label>
        <Form.Control
          placeholder={'Address'}
          value={formData.address}
          name='address'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          placeholder={'555-123-2345'}
          value={formData.phoneNumber}
          name='phoneNumber'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>
    </Form>
  );
};

export default NewEstablishmentForm;
