import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

const establishmentState = (state: DefaultRootState) => state.establishments;

export const fetchAllEstablishmentsSelector = createSelector(
  establishmentState,
  ({ establishments }) => {
    return establishments;
  }
);
