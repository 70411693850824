import { FC } from 'react';
import PasswordResetForm from './PasswordResetForm';

const PasswordReset: FC = function PasswordReset() {
    return (
        <div className="text-center">
            <h5>Reset new password</h5>
            <PasswordResetForm hasLabel={false} />
        </div>
    )
};

export default PasswordReset;
