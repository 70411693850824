import { toast, Slide, ToastContent, ToastOptions } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const toasterDefaultOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  theme: "colored",
  hideProgressBar: true,
  transition: Slide
};

export const ToastService = {

  success: (content: ToastContent<unknown> | string, options?: ToastOptions) => {
    toast.success(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  error: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.error(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  warn: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.warn(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },

  info: (content: ToastContent<unknown>, options?: ToastOptions) => {
    toast.info(content, {
      ...toasterDefaultOptions,
      ...options,
    });
  },
};
