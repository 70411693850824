import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILogin } from './ILogin';
import { EAuth } from './constants';
import apiClient, { saveTokens } from '../../middleware/apiClient';

const login = createAsyncThunk(EAuth.login, async (payload: ILogin) => {
  const response = await apiClient.post('auth/login', payload);
  const {
    data: { accessToken, refreshToken, ...newUser },
  } = response;

  saveTokens(accessToken, refreshToken);

  return newUser;
});

export default login;
