import { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import NewAdminForm from './NewAdminForm';
import { useAppDispatch } from 'hooks/redux.hooks';
import inviteAdmin from 'features/users/inviteAdminSlice';
import { ToastService } from 'services/ToastService';
import fetchUsers from 'features/users/fetchUsersSlice';

interface IModal {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
  onClose?: () => void;
}

const NewAdminModal: FC<IModal> = ({ isModalOpen, setModalOpen, onClose }) => {
  const [formData, setFormData] = useState({
    emailAddress: '',
    firstName: '',
    lastName: '',
  });
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    setModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  // Handler
  const handleSubmit = (e: any): void => {
    e.preventDefault();
    dispatch(inviteAdmin(formData))
      .unwrap()
      .then((data: any) => {
        if (data) {
          ToastService.success('Invitation sent');
        }
        dispatch(fetchUsers());
        handleModalClose();
      });
  };

  const handleFieldChange = (e: any): void => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invite Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewAdminForm formData={formData} handleFieldChange={handleFieldChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='submit'
          color='primary'
          className='mt-3 w-100'
          disabled={!formData.emailAddress || !formData.firstName || !formData.lastName}
          onClick={handleSubmit}
        >
          Send Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewAdminModal;
