import { createSlice } from '@reduxjs/toolkit';
import { IBasicUser } from '../../types/auth/user';
import fetchMe from './fetchMeSlice';

export interface AuthState {
  loading: boolean;
  loaded: boolean;
  user: IBasicUser | any;
}

const initialState: AuthState = {
  loading: true,
  loaded: false,
  user: null,
};

const { reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchMe.pending, state => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        const {
          payload: { agency, consultant, customer, ...user },
        } = action;
        return {
          ...state,
          loading: false,
          loaded: true,
          user,
        };
      }),
});

export default reducer;
