import { EUsers } from './constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'middleware/apiClient';

export interface ICancelAdminInvite {
  userId: number;
  userEmail: string;
}

const cancelAdminInvite = createAsyncThunk(
  EUsers.cancelAdminInvite,
  async (payload: ICancelAdminInvite) => {
    const response = await apiClient.post('admin/admin-users/cancel-invite', payload);
    return response.data;
  }
);

export default cancelAdminInvite;
