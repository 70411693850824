import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ILogo } from 'types';
import Image from 'react-bootstrap/Image';

const Logo: FC<ILogo> = function Logo({ at = 'auth', width = 58, className, textClass, ...rest }) {
  return (
    <Link
      to='/'
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth',
          },
          className
        )}
      >
        <Image src='/img/phrenzi-clover.png' width={width} />
        <span className={classNames('font-sans-serif', textClass)}>Phrenzi</span>
      </div>
    </Link>
  );
};

export default Logo;
