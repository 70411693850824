import { FC, useEffect, useState } from 'react';
import { Button, Container, Row, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { IBasicUser } from '../../types/auth/user';
import { getUsersSelector } from '../../features/users/getUsersSelector';
import NewAdminModal from './NewAdminModal';
import fetchUsers from 'features/users/fetchUsersSlice';
import { EUserStatus } from 'types/enums';
import { getMeSelector } from 'features/auth/authSelector';
import cancelAdminInvite from 'features/users/cancelInviteSlice';
import resendInvite from 'features/users/resendInviteSlice';
import { ToastService } from 'services/ToastService';

const Users: FC = () => {
  const userList = useAppSelector(getUsersSelector) as IBasicUser[];
  const me = useAppSelector(getMeSelector) as IBasicUser;
  const dispatch = useAppDispatch();
  //get the list of users from the app dispatch
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleAddClick = () => {
    setModalOpen(true);
  };

  const handleDeleteClick = (user: IBasicUser) => {
    //delete user
    console.log('delete user', user);
  };

  const handleResendInviteClick = (user: IBasicUser) => {
    //resend invite
    dispatch(resendInvite({ email: user.email, firstName: user.firstName }))
      .unwrap()
      .then(() => {
        ToastService.success('Invitation sent again');
      });
  };

  const handleCancelInviteClick = (user: IBasicUser) => {
    //cancel invite
    dispatch(cancelAdminInvite({ userId: user.id, userEmail: user.email }))
      .unwrap()
      .then(() => {
        dispatch(fetchUsers());
      });
  };

  const renderButtons = (user: IBasicUser) => {
    //if user is active, show delete button
    //if user is pending, show resend invite button
    console.log('render User buttons: ', user.userStatus.status);
    if (user.userStatus.status === EUserStatus.ACTIVE && me.id !== user.id) {
      return (
        <Button variant='danger' onClick={() => handleDeleteClick(user)}>
          Delete
        </Button>
      );
    } else if (user.userStatus.status === EUserStatus.PENDING) {
      return (
        <div>
          <Button variant='primary' onClick={() => handleResendInviteClick(user)}>
            Resend Invite
          </Button>
          <Button variant='danger' onClick={() => handleCancelInviteClick(user)}>
            Cancel Invite
          </Button>
        </div>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userList.map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.userStatus?.status}</td>
                <td>{renderButtons(user)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      {/* button here to open a boostrap modal window */}
      <Button variant='primary' onClick={handleAddClick}>
        Add User
      </Button>
      <NewAdminModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </Container>
  );
};

export default Users;
