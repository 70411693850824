enum ROUTES {
  HOME = '/',
  AUTH = '/auth',
  FORGOT_PASSWORD = '/auth/forgot-password',
  PASSWORD_RESET = '/auth/reset-password',
  SIGN_UP = '/auth/signup',
  DASHBOARD = '/dashboard',
  USERS = '/users',
  ESTABLISHMENTS = '/establishments',
  ERROR_500 = '/errors/500',
}

export default ROUTES;
