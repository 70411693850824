import { createSlice } from '@reduxjs/toolkit';
import { IBasicEstablishment } from 'types/establishments/establishment';
import fetchEstablishments from './fetchAllEstablishmentsSlice';

export interface EstablishmentState {
  loading: boolean;
  loaded: boolean;
  establishments: IBasicEstablishment | any;
}

const initialState: EstablishmentState = {
  loading: true,
  loaded: false,
  establishments: [],
};

const { reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchEstablishments.pending, state => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchEstablishments.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          establishments: action.payload,
        };
      }),
});

export default reducer;
