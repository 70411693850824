import { useState, FC } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { IForgotPassword } from '../../types/auth/password';


const ForgotPasswordForm: FC<IForgotPassword> = function ForgetPasswordForm() {
    // State
    const [email, setEmail] = useState('');

    // Handler
    const handleSubmit = (e: any): void => {
        e.preventDefault();
        if (email) {
            toast.success(`An email is sent to ${email} with password reset link`, {
                theme: 'colored'
            });
        }
    };

    return (
        <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control placeholder="Email address" value={email} name="email" onChange={({ target }): void => setEmail(target.value)} type="email" />
            </Form.Group>

            <Form.Group className="mb-3">
                <Button className="w-100" type="submit" disabled={!email}>
                    Send reset link
                </Button>
            </Form.Group>
        </Form>
    );
};

export default ForgotPasswordForm;
