import { combineReducers, Action } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './features/auth/authReducer';
import usersReducer, { UsersState } from './features/users/userReducer';
import establishmentReducer, {
  EstablishmentState,
} from 'features/establishments/establishmentsReducer';

export type TRootState = {
  auth: AuthState;
  users: UsersState;
  establishments: EstablishmentState;
};

const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  establishments: establishmentReducer,
});

const rootReducer = (state: any, action: Action) => {
  return appReducer(state, action);
};

export default rootReducer;

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends TRootState {}
}
