import { EUsers } from './constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'middleware/apiClient';

export interface IResendAdminInvite {
  email: string;
  firstName: string;
}

const resendInvite = createAsyncThunk(
  EUsers.cancelAdminInvite,
  async (payload: IResendAdminInvite) => {
    const response = await apiClient.post('admin/admin-users/resend-invite', payload);
    return response.data;
  }
);

export default resendInvite;
