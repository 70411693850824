import { EUsers } from './constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'middleware/apiClient';

export interface IInviteAdmin {
  emailAddress: string;
  firstName: string;
  lastName: string;
}

const inviteAdmin = createAsyncThunk(EUsers.inviteAdmin, async (payload: IInviteAdmin) => {
  const response = await apiClient.post('admin/admin-users/invite-admin', payload);
  return response.data;
});

export default inviteAdmin;
