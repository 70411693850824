import { FC } from 'react';

interface IToastMessage {
  body: string;
  title: string;
}

const ToastMessage: FC<IToastMessage> = ({ title, body }) => (
  <>
    <div>{title}</div>
    <div>{body}</div>
  </>
);

export default ToastMessage;
