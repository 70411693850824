import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../middleware/apiClient';
import { EUsers } from './constants';

const fetchUsers = createAsyncThunk(EUsers.getUsers, async () => {
  const { data } = await apiClient.get(`users`);
  return data;
});

export default fetchUsers;
