import { useState, FC } from 'react';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { IPasswordResetForm } from '../../types/auth/password';
import { ToastService } from '../../services/ToastService';

const PasswordResetForm: FC<IPasswordResetForm> = function PasswordResetForm({ hasLabel }) {
    // State
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    // Handler
    const handleSubmit = (e: any): void => {
        e.preventDefault();
        ToastService.success('Login with your new password');
    };

    const handleFieldChange = (e: any): void => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>New Password</Form.Label>}
                <Form.Control placeholder={!hasLabel ? 'New Password' : ''} value={formData.password} name="password" onChange={handleFieldChange} type="password" />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Confirm Password</Form.Label>}
                <Form.Control placeholder={!hasLabel ? 'Confirm Password' : ''} value={formData.confirmPassword} name="confirmPassword" onChange={handleFieldChange} type="password" />
            </Form.Group>

            <Button type="submit" className="w-100" disabled={!formData.password || !formData.confirmPassword}>
                Set password
            </Button>
        </Form>
    );
};


export default PasswordResetForm;
