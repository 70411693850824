//Sign up react functional component for the application
//Form accepts email, password and password confirmation

import React, { FC } from 'react';
import Flex from 'components/common/Flex';
import SignUpForm from './SignUpForm';
import { useLoaderData, useParams } from 'react-router-dom';

const Signup: FC = () => {
  const data = useLoaderData() as any;
  const { hash } = useParams();
  return (
    <>
      <Flex justifyContent='between' alignItems='center' className='mb-2'>
        <h5>Sign Up</h5>
      </Flex>
      <SignUpForm hashCode={hash} email={data.email} />
    </>
  );
};

export default Signup;
