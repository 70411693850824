import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'middleware/apiClient';
import { EEstablishment } from './constants';

export interface ICreateEstablishment {
  name: string;
  ownerName: string;
  phoneNumber: string;
  address: string;
}

const createEstablishment = createAsyncThunk(
  EEstablishment.create,
  async (payload: ICreateEstablishment) => {
    const response = await apiClient.post('establishments', payload);
    return response.data;
  }
);

export default createEstablishment;
