import {FC } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';


const ForgotPassword: FC = function ForgetPassword() {
    return (
        <div className="text-center">
            <h5 className="mb-0"> Forgot your password?</h5>
            <small>Enter your email and we&apos;ll send you a reset link.</small>
            <ForgotPasswordForm />
        </div>
    );
};

export default ForgotPassword;
